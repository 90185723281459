<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<resources-form-header :modelPk="modelPk"></resources-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.resources.tabs.maintab') }}</v-tab>
				<v-tab v-if="!isCreatingElement && hasResourceBlockFunctionality" :key="1" :href="'#block'">{{
					$t('form.resources.tabs.block')
				}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-flex xs12>
						<resources-form-main-tab
							:modelName="modelName"
							:parentObject="this.model"
							:formDisabled="formDisabled"
							:isCreatingElement="isCreatingElement"
						></resources-form-main-tab>
					</v-flex>
				</v-tab-item>
				<v-tab-item :key="1" lazy :value="'block'" v-if="!isCreatingElement && hasResourceFunctionality">
					<v-flex xs12>
						<pui-master-detail
							componentName="resourceblockgrid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ resourceid: 'resourceid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:parentModel="model"
						></pui-master-detail>
					</v-flex>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="saveAndUpdate" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import ResourcesFormHeader from './ResourcesFormHeader.vue';
import ResourcesFormMainTab from './ResourcesFormMainTab.vue';
import ResourceblockGrid from '../resourceblock/ResourceblockGrid.vue';

export default {
	name: 'resources-form',
	mixins: [PuiFormMethodsMixin],
	components: { ResourcesFormMainTab, ResourceblockGrid, ResourcesFormHeader },
	data() {
		return {
			modelName: 'resources'
		};
	},
	methods: {
		afterGetData() {
			console.log(this.model);
		}
	},
	computed: {
		hasResourceBlockFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_RESOURCEBLOCK');
		},
		hasResourceFunctionality() {
			return this.$store.getters.getSession.functionalities.includes('READ_RESOURCES');
		}
	},
	created() {}
};
</script>
